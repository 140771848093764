<template>
  <div class="notification">
       <p class="Pro mt-15 ml-12"><strong>Notifications</strong> </p>
       <div :key="index" v-for="(i, index) in 8" class="noti">
         <div class="dot">
           <div class="thedot ml-4"/>
         </div>
         <div class="msg">
           <p class="msgd">Your order #42215 has just been completed. Thank you</p>
         </div>
         <div class="date">
           <p class="dated">01/20/2021</p>
         </div>
       </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.notification{
    max-height: 90vh;
    overflow: scroll;
}
.noti{
  width: 584px;
  min-height: 70px;
  margin: 31px 107px 13px 56px;
  border-radius: 10px;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.08);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 10px;
}
.dot{
flex: 0.7;
}
.thedot{
 height: 30px;
 width: 30px;
 border-radius: 50%;
 background-color: #1156aa;
}
.msg{
flex: 3;
}
.date{
  flex: 0.5;
}
.dated{
  height: 20px;
  margin: 6px 0 0 28px;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #7c7a7a;
}
.msgd{
  margin: 3px 28px 7px 26px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: left;
  color: #374146;
}
</style>